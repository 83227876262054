<template>
  <section id="pricing-plan">
    <!-- pricing plan cards -->
    <b-row class="pricing-card">
      <b-col offset-sm-2 sm="10" md="12" offset-lg="2" lg="10" class="mx-auto">
        <b-row>
          <b-col
            v-for="item in questionnaires"
            :key="item.id"
            :md="pricingCols"
          >
            <b-card align="center">
              <h3>{{ item.title }}</h3>
              <b-card-text>
                <div v-html="item.description" />
              </b-card-text>

              <div>
                {{ $t('Start Date') }}:
                <strong>{{ shortDate(item.startDate) }}</strong>
              </div>

              <div>
                {{ $t('End Date') }}:
                <strong>{{ shortDate(item.endDate) }}</strong>
              </div>
              <!-- <div v-if="item.answer" class="mt-2">
                <hr />
                {{ $t('Answer Date') }}:
                <strong class="text-primary">
                  {{ shortDate(item.answer.creationDate) }}
                </strong>
              </div> -->

              <!-- buttons -->
              <b-button
                v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                :to="{
                  name: 'answers-list',
                  params: {
                    id: item.id,
                  },
                }"
                variant="primary"
                block
                class="mt-2"
              >
                {{ $t('My Entries') }}
              </b-button>
            </b-card>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import axios from '@/libs/axios'
import Ripple from 'vue-ripple-directive'

export default {
  directives: {
    Ripple,
  },
  data() {
    return {
      questionnaires: [],
    }
  },
  computed: {
    pricingCols() {
      return 6
    },
  },
  created() {
    axios.get(`questionnaires/recipient`).then(response => {
      this.questionnaires = response.data
    })
  },
}
</script>
